import React from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'

import GlobalWrapper from 'components/global-wrapper'
import Header from 'components/article/header'
import ContentWrapper from 'components/article/contentwrapper'
import Footer from 'components/footer'
import Portrait from 'img/portrait.jpg'
import LargeImage from 'components/article/largeimage'

const Section = styled.section`
  margin: 64px 0;
  ${media.sm`
    margin: 0;
  `}
`

const HoverLink = styled.a`
  color: black;
  text-decoration: none;

  &:hover {
    color: orange;
  }

  &:visited {
    color: gray;
  }
`;

const CV = () => {
  return (
    <GlobalWrapper>
      <Header title="Curriculum Vitae" />
	<br></br>
      	<div style={{ maxWidth: '150%', maxHeight: '455px' }}>
        <LargeImage src={Portrait} />
      </div>
      <Section>
        <ContentWrapper>
          <h2>About</h2>
          <p>
	Qais is a cybersecurity professional with strong personal integrity, attention to detail, and the ability to quickly grasp new threats and technologies.
        He has secured organizations across the aerospace, automotive, healthcare, technology, and government sectors.
	</p>
        </ContentWrapper>
        <ContentWrapper>
          <h2>Experience</h2>
          <p>
            <strong>Penetration Tester</strong> — Municipality of Oslo<br/>
            Jan 2024 - Dec 2024<br/>
            • Conducted security testing and retesting.<br/>
            • Prepared reports based on OWASP and CVSS.<br/>
            • Planned, executed, and reported on phishing exercises.<br/>
	    • Analyzed logs with Microsoft Sentinel and KQL.<br/>
	    • Presented reports to clients and assisted developers with remediation.<br/>
            </p>
            <p>
            <strong>Photographer</strong> — Independent<br/>
            • Taken on various assignments over the years.<br/>
            • Focusing on architecture, portraits, cars, and events.<br/>
          </p>
        </ContentWrapper>
	<ContentWrapper>
          <h2>Certifications</h2>
            <p>
            <strong>Offsec Certified Professional (OSCP) - In Progress</strong><br/>

            <HoverLink href="https://certs.ine.com/efb9a9d6-7fe2-45bd-aa1f-4dfb8b883823" target="_blank" rel="noopener noreferrer">
            <strong>Certified Professional Penetration Tester (eCPPT)</strong><br/>
            </HoverLink>
            
            <HoverLink href="https://certs.ine.com/4a091154-4ae1-4287-b8a3-a1dea3d12cc1" target="_blank" rel="noopener noreferrer">
            <strong>Web Application Penetration Tester (eWPT)</strong><br/>
            </HoverLink>
            </p>
        </ContentWrapper>
        <ContentWrapper>
          <h2>Courses</h2>
          <p>
            <strong>The Bug Hunter's Methodology</strong><br/>
            <strong>NSM Grunnprinsipper for IKT-sikkerhet</strong><br/>
	    <strong>The Web Application Hacker's Handbook</strong><br/>
            <strong>Foundations of Operationalizing MITRE ATT&CK</strong><br/>
            <strong>PEN-200: Penetration Testing Certification with Kali Linux</strong><br/>
          </p>
	</ContentWrapper>

        <ContentWrapper>
        <h2>Accolades</h2>
        <p>
        <HoverLink href="https://qaisqais.com/documents/nasa.png" target="_blank" rel="noopener noreferrer">
        <strong>National Aeronautics and Space Administration (NASA)</strong> — Letter<br/>
        </HoverLink>

        <HoverLink href="https://qaisqais.com/documents/drexel.png" target="_blank" rel="noopener noreferrer">
        <strong>Drexel University</strong> — Letter<br/>
        </HoverLink>

        <HoverLink href="https://www.nhn.no/.well-known/hall-of-fame.html" target="_blank" rel="noopener noreferrer">
        <strong>Norsk Helsenett SF</strong> — Hall of Fame<br/>
        </HoverLink>

 	<HoverLink href="https://hhs.responsibledisclosure.com/hc/en-us/articles/1500000280921-Acknowledgments" target="_blank" rel="noopener noreferrer">
        <strong>Department of Health & Human Services</strong> — Hall of Fame<br/>
        </HoverLink>	

        <HoverLink href="https://www.ferrari.com/en-EN/hall-of-fame-responsible-disclosure-programme" target="_blank" rel="noopener noreferrer">
        <strong>Ferrari S.p.A</strong> — Hall of Fame<br/>
        </HoverLink>
	
        <HoverLink href="https://www.fastweb.it/corporate/responsible-disclosure/?lng=EN" target="_blank" rel="noopener noreferrer">
        <strong>Fastweb S.p.A</strong> — Hall of Fame<br/>
        </HoverLink>

        <HoverLink href="https://www.nokia.com/notices/responsible-disclosure/" target="_blank" rel="noopener noreferrer">
        <strong>Nokia Corporation</strong> — Hall of Fame<br/>
        </HoverLink>

        <HoverLink href="https://www.cambridge.org/legal/security-and-vulnerability-disclosure-policy/acknowledgement" target="_blank" rel="noopener noreferrer">
        <strong>Cambridge University</strong> — Hall of Fame<br/>
        </HoverLink>

        <HoverLink href="https://www.proofpoint.com/us/security/hall-of-fame" target="_blank" rel="noopener noreferrer">
        <strong>Proofpoint, Inc.</strong> — Hall of Fame<br/>
        </HoverLink>
	
        <HoverLink href="https://unite.un.org/content/hall-fame/list" target="_blank" rel="noopener noreferrer">
        <strong>United Nations</strong> — Hall of Fame<br/>
        </HoverLink>

        <HoverLink href="https://cert.europa.eu/hall-of-fame" target="_blank" rel="noopener noreferrer">
        <strong>EU</strong> — Hall of Fame<br/>
        </HoverLink>

        <HoverLink href="https://www.philips.com/a-w/security/coordinated-vulnerability-disclosure/hall-of-honors.html#slide_#" target="_blank" rel="noopener noreferrer">
        <strong>Philips</strong> — Hall of Fame<br/>
        </HoverLink>

        <HoverLink href="https://www.oracle.com/security-alerts/cpujan2024.html" target="_blank" rel="noopener noreferrer">
        <strong>Oracle Corporation</strong> — On-Line Presence Security Contributor<br/>
        </HoverLink>

        <HoverLink href="https://www.exploit-db.com/google-hacking-database?author=12031" target="_blank" rel="noopener noreferrer">
        <strong>Google Hacking Database (GHDB)</strong> — Contributor<br/>
        </HoverLink>
        </p>
        </ContentWrapper>

        <ContentWrapper>
          <h2>Skills</h2>
          <p>
            <strong>Penetration Testing</strong> (Infrastructure, Application, Cloud)<br/>
            <strong>Phishing</strong> (Microsoft Attack Simulation, GoPhish)<br/>
            <strong>SIEM</strong> (Microsoft Defender for Endpoint, Splunk)<br/>
            <strong>Scripting & Automation</strong> (Bash, Python, PowerShell)<br/>
            <strong>Reconnaissance</strong> (OSINT, Asset Discovery, Scanning)<br/>
	    <strong>Privacy & Encryption</strong> (PGP, Whonix, Secure Communication)<br/>
            <strong>Physical Security</strong> (RFID, Badge Cloning, Lock Picking)<br/>
            <strong>Post Processing</strong> (Editing, Design, Reporting)<br/>
          </p>
        </ContentWrapper>

        <ContentWrapper>
          <h2>Languages</h2>
          <p>
            <strong>Norwegian</strong><br/>
            <strong>English</strong><br/>
            <strong>Arabic</strong><br/>
            <strong>Russian</strong><br/>
          </p>
        </ContentWrapper>
	</Section>
      <Footer />
    </GlobalWrapper>
  )
}

export default CV
